<template>
  <div class="main-wrapper">
    <!-- <title-bar title="About Us" subtitle="Explore your search places"></title-bar> -->
    <div class="content pt-0">
      <div class="about-wrapper">
        <div class="block-space bg-secondary">
          <div class="container">
            <div class="row row-grid align-items-center">
              <div class="col-md-6">
                <div class="card bg-default shadow border-0">
                  <img src="/static/images/miembros-fiaf.jpg" class="card-img-top" />
                  <blockquote class="card-blockquote">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      class="svg-bg"
                    >
                      <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                      <polygon
                        points="0,42 583,95 683,0 0,95"
                        opacity=".2"
                        class="fill-default"
                      ></polygon>
                    </svg>
                    <!-- <h3 class="font-weight-bold text-white">
											{{ $t('Views.FrontAbout.title2') }}
										</h3>
										<p class="lead text-italic text-white">
											{{ $t('Views.FrontAbout.desc2') }}
										</p> -->
                  </blockquote>
                </div>
              </div>
              <div class="col-md-6">
                <div class="pl-md-5">
                  <h3>{{ $t('Views.FrontAbout.title1') }}</h3>
                  <p class="lead">
                    {{ $t('Views.FrontAbout.desc1') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="block-space">
              <h3 class="block-head mb-4">{{ $t('Views.FrontAbout.missionTitle') }}</h3>
              <v-row class="mx-0">
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-icon x-large dense class="col-12 col-sm-2 p-1"
                          >mdi-copyright</v-icon
                        >
                        <p class="col-12 col-sm-10">
                          {{ $t('Views.FrontAbout.mission1') }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-icon x-large class="col-12 col-sm-2 p-1"
                          >mdi-handshake-outline</v-icon
                        >
                        <p class="col-12 col-sm-10">
                          {{ $t('Views.FrontAbout.mission2') }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-icon x-large class="col-12 col-sm-2 p-1"
                          >mdi-account-tie-voice</v-icon
                        >
                        <p class="col-12 col-sm-10">
                          {{ $t('Views.FrontAbout.mission3') }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-icon x-large class="col-12 col-sm-2 p-1"
                          >mdi-book-open-variant</v-icon
                        >
                        <p class="col-12 col-sm-10">
                          {{ $t('Views.FrontAbout.mission4') }}
                        </p>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <!-- DIRECTIVE  -->
        <directors
          class="mx-sm-5 mx-md-auto mx-lg-5"
          :items="directors"
          :loading="loadingDirectors"
          :title="$t('Views.FrontAbout.directorsTitle')"
        />
        <!-- FORMER DECRETARIES -->
        <former-secretaries
          :items="secretaries"
          :title="$t('Views.FrontAbout.exDirectorsTitle')"
        />

        <div class="card bg-gradient-teal shadow-lg border-0 mx-5">
          <!-- <div class=" "> -->
          <!-- <div class="p-4"> -->
          <div class="row m-0 align-items-center justify-content-end">
            <div class="col-md-9 p-md-4">
              <h3 class="text-white">{{ $t('Views.FrontAbout.codeTitle') }}</h3>
              <p class="lead text-white mt-3">
                {{ $t('Views.FrontAbout.codeDesc') }}
              </p>
            </div>
            <div class="col-md-3 mx-0 mx-md-auto p-md-4 text-center">
              <a
                href="/static/Codigo-de-Etica-FIAF.pdf"
                class="btn btn-primary badge badge-primary text-uppercase badge-cat p-3 bg-primary text-white mx-auto"
                target="_blank"
                rel="nopener"
                >{{ $t('Views.FrontAbout.codeButton') }}</a
              >
            </div>
          </div>
        </div>

        <companies-carousel />

        <parallax-section
          :title="$t('Views.FrontAbout.affiliateTitle')"
          :desc="$t('Views.FrontAbout.affiliateDesc')"
          :button="$t('Views.FrontAbout.affiliateButton')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ParallaxSection from 'Components/FrontendComponents/ParallaxSection'
  import CompaniesCarousel from 'Components/FrontendComponents/CompaniesCarousel'
  import FormerSecretaries from 'Components/FrontendComponents/FormerSecretaries'
  import Directors from 'Components/FrontendComponents/Directors.vue'
  export default {
    name: 'About',
    data() {
      return {
        directors: [],
        secretaries: [],
        loadingDirectors: false,
        loadingFormerSecretaries: false,
      }
    },
    components: {
      ParallaxSection,
      CompaniesCarousel,
      FormerSecretaries,
      Directors,
    },
    created() {
      this.getDirectors()
      this.getFormerSecretaries()
    },
    methods: {
      async getDirectors() {
        this.loadingDirectors = true
        axios
          .get('directors?order=order&by=asc&company_id=null&type=1&limit=10')
          .then((response) => {
            this.directors = response.data
            this.loadingDirectors = false
          })
      },
      async getFormerSecretaries() {
        this.loadingFormerSecretaries = true
        axios
          .get('directors?order=order&by=asc&company_id=null&type=2&limit=10')
          .then((response) => {
            this.secretaries = response.data
            this.loadingFormerSecretaries = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .listing-item-container.list-layout .listing-item {
    width: 100%;
    height: 200px;
  }
</style>
